
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { useCameraStore } from "@/stores/camera"

import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"

export default {
  meta: {
    public: true,
  },
  head() {
    return {
      title: "Evercam | Confirm Email",
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useAccountStore),
  },
  mounted() {
    this.confirmUserEmail()
  },
  methods: {
    async confirmUserEmail() {
      let payload = {
        c: this.$route.query.c,
      }
      try {
        const data = await EvercamApi.users.confirm(
          this.$route.query.u,
          payload
        )
        this.$axios.setToken(data.token, "Bearer")
        const camerasJson = await EvercamApi.cameras.getCameras()
        this.accountStore.updateUser(data)
        this.accountStore.token = data.token
        useCameraStore().cameras = camerasJson
        this.$notifications.success(this.$t("content.email_verified"))
        if (this.accountStore.redirectUrl) {
          let url = this.accountStore.redirectUrl
          this.$router.push(url)
        } else {
          this.$router.push("/v2/projects")
        }
      } catch (error) {
        this.$notifications.error({ error })
      }
    },
  },
}
